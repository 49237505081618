import StyledImage from '@/components/styledImage';
import type { AddressBase, Company, Staff } from '@/types/schema';
import { InvoiceLayoutTemplateType } from '@/types/templates';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export default function CompanyInfo( {
	company,
	address,
	staff,
	layout,
	removeImage,
	companyNameSX,
	companyInfoSX,
}: {
	company?: Company,
	address?: AddressBase,
	layout?: InvoiceLayoutTemplateType,
	staff?: Staff,
	removeImage?: boolean,
	companyNameSX?: SxProps,
	companyInfoSX?: SxProps
} ) {
	return (
		<Fragment>
			{!removeImage && ( company?.logo && (
				<StyledImage
					alt='image'
					src={company?.logo}
					sx={{
						height      : { xs: 'auto', sm: 'auto' },
						maxHeight   : { xs: 75, sm: 100 },
						maxWidth    : { xs: 140, sm: 200 },
						borderRadius: 2,
					}}
				/>
			) )}
			{company?.name && (
				<Typography
					className='companyName'
					sx={{
						fontSize  : { sm: '1.5rem !important' },
						fontWeight: 'bold',
						wordBreak : 'break-word',
						...companyNameSX,
					}}>
					{company.name}
				</Typography>
			)}
			{address?.line1 && (
				<Box>
					{address?.line1 && (
						<Typography sx={{ ...companyInfoSX }}>
							{[ address.line1, address.line2 ].filter( Boolean ).join( ', ' )}
						</Typography>
					)}
					{address?.city && address?.state && address?.postalCode && (
						<Typography sx={{ ...companyInfoSX }}>
							{[ address.city, address?.state, address.postalCode ].filter( Boolean ).join( ', ' )}
						</Typography>
					)}
					{company.phone && (
						<Typography sx={{ ...companyInfoSX }}>
							{company.phone.replace( /^\+1\s*/, '' )}
						</Typography>
					)}
				</Box>
			)}
			{company?.email && (
				<Typography sx={{ fontSize: { sm: '1.4rem' }, ...companyInfoSX }}>
					{company.email}
				</Typography>
			)}
			{company?.website && (
				<Typography sx={{ fontSize: { sm: '1.4rem' }, ...companyInfoSX }}>
					{company.website}
				</Typography>
			)}
			{company?.metadata?.showStaffOnReceipt && staff?.user?.firstName && (
				<Stack direction='row' sx={{ justifyContent: layout === 'consulting' ? 'end' : 'start' }}>
					<Typography sx={{ pr: .3 }}>By </Typography>
					<Typography sx={{ ...companyInfoSX, color: 'text.secondary' }}>
						{staff.user.firstName} {staff.user?.lastName?.[ 0 ] || ''}.
					</Typography>
				</Stack>
			)}
		</Fragment>
	);
}
