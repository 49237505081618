import CustomTooltip from '@/components/popups/customTooltip';
import TableWrapper from '@/components/tableWrapper';
import currencyFormat from '@/helpers/currencyFormat';
import safeDateFns, { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { isEstimateType } from '@/helpers/useIsEstimateType';
import { findEarliestDate } from '@/modals/repeatDateModal/utils';
import DeliveryStatusChip from '@/pages/dashboard/commerce/chips/deliveryStatusChip';
import { getGrandTotalAndTextTotal } from '@/pages/dashboard/commerce/components/calculations';
import type { Order, Staff } from '@/types/schema';
import { InvoiceLayoutTemplateType } from '@/types/templates';
import {
	CalendarMonthRounded as CalendarMonthRoundedIcon,
	HelpCenterRounded as HelpCenterRoundedIcon,
} from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Stack, TableCell, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { startCase, toLower, upperFirst } from 'lodash-es';
import React, { Fragment } from 'react';
import CommerceStatusChip from '../../../dashboard/commerce/chips/commerceStatusChip';
import { trimServiceDate } from './clientView/invoiceSelection';

export default function InvoiceInfo( {
	invoice,
	timezone,
	removeTitle,
	invoicesBalance,
	staff,
	pickup,
	isPdf,
	selectionsMenu,
}: {
	invoice: Order,
	timezone?: string,
	removeTitle?: boolean,
	layout?: InvoiceLayoutTemplateType,
	invoicesBalance?: number,
	staff?: Staff,
	pickup?: boolean,
	isPdf?: boolean,
	selectionsMenu?: string[]
} ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const startingDate: Date = findEarliestDate( invoice.standingData, timezone );
	
	const paidTotal = invoice.payments?.reduce( ( total, payment ) => {
		if ( ![ 'invoiss', 'haps' ].includes( toLower( payment.type ) ) && payment.status === 'PAID' ) {
			total += payment.amount;
		}
		if ( [ 'REFUNDED', 'PARTIALLY_REFUNDED' ].includes( payment.status ) ) {
			total -= payment.refundedAmount;
		}
		return total;
	}, 0 ) || 0;
	const { grandTotal } = getGrandTotalAndTextTotal( invoice );
	const remainingAmount = Math.max( grandTotal - paidTotal, 0 );
	
	const startsContent = safeFormatInTimeZone( invoice.standingData?.type !== 'NONE'
		? startingDate || invoice.standingDate
		: safeDateFns.min( invoice.standingData?.include ), 'PP', timezone );
	
	const hasSameCompany = staff?.company?.id === invoice.company.id;
	const selections = selectionsMenu ? [ ...selectionsMenu ] : invoice.company.metadata?.selectionsMenu || [];
	
	const { hideServiceDate, hideDateTime } = trimServiceDate( invoice, selections );
	
	return (
		<Fragment>
			{!removeTitle && (
				<Typography
					sx={{
						fontWeight: '500',
						textAlign : 'right',
						fontSize  : { xs: '2.0848rem !important', sm: '3.0848rem !important' },
					}}>
					{!pickup ? startCase( toLower( invoice.type ) ) : 'Packing Slip'}
				</Typography>
			)}
			<Stack
				spacing={.6}
				sx={{
					'.MuiTableCell-root:first-of-type': {
						width: { xs: '100%' },
						py   : 0,
					},
					'.MuiTableCell-root:last-of-type': {
						textAlign: { xs: 'right !important', sm: 'right !important' },
						width    : { xs: '100%' },
						wordBreak: 'break-word',
						py       : 0,
					},
					'td': {
						fontSize: '1.3rem',
					},
				}}>
				{invoice.number && (
					<TableWrapper>
						<TableCell align='right'>
							<Typography sx={{ color: 'text.secondary' }}>
								#{invoice.metadata?.customNumber || invoice.number}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{!invoice.standing && invoice.standingDate && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Issue on:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>
								{safeFormatInTimeZone( invoice.standingDate, 'PP', timezone )}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{/* For non-standing invoices with a service date */}
				{!invoice.standing && !hideServiceDate && invoice.serviceDate && (
					<TableWrapper>
						{!isMobile && (
							<TableCell sx={{ width: '40% !important' }}>
								<Typography color='text.secondary'>
									Service on:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>
								{safeFormatInTimeZone( invoice.serviceDate, hideDateTime ? 'PP' : 'PPp', timezone )}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{invoice.standing && startsContent && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Starts:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>{startsContent}</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{/* For standing invoices that repeat */}
				{invoice.standing && Boolean( invoice.standingData?.type ) && invoice.standingData?.type !== 'NONE' && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Repeats:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>
								Every {invoice.standingData?.multiple > 1
								? invoice.standingData.multiple
								: ''} {upperFirst( toLower( invoice.standingData.type ) )}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{invoice.standing && invoice.standingData?.type === 'WEEK' ? (
					<Stack
						direction='row'
						spacing={0.2}
						sx={{
							'& .MuiAvatar-root': { width: 25, height: 25, fontSize: 11 },
							'justifyContent'   : 'end',
							'mt'               : 1,
						}}>
						{[ 'Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa' ].map( ( day ) => (
							<Avatar
								key={day}
								sx={invoice.standingData.repeat[ day ] ? { bgcolor: 'primary.main' } : undefined}>
								{day}
							</Avatar>
						) )}
					</Stack>
				) : undefined}
				
				{/* For standing invoices with selected days */}
				{invoice.standing && Boolean( invoice.standingData?.include?.length ) && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Custom Days:
								</Typography>
							</TableCell>
						)}
						<TableCell>
							<CustomTooltip
								toolTipChildren={() => (
									<Box>
										{invoice.standingData?.include?.map( ( date, index ) => (
											<Chip
												key={index}
												variant='outlined'
												color='primary'
												label={safeFormatInTimeZone( date, 'PPP', timezone )}
												sx={{ mr: 1, mb: 1 }}
											/>
										) )}
									</Box>
								)}>
								<CalendarMonthRoundedIcon sx={{ fontSize: 16, borderRadius: 1, color: 'text.secondary' }}/>
							</CustomTooltip>
						</TableCell>
					</TableWrapper>
				)}
				{invoice.standing && invoice.standingData?.endDate && invoice?.standingData?.ends !== 'NEVER' ? (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Ends:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>
								{safeFormatInTimeZone( invoice.standingData?.endDate, 'PP', timezone )}
							</Typography>
						</TableCell>
					</TableWrapper>
				) : null}
				{invoice.standing && invoice?.standingData.ends === 'OCCURRENCES' && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Occurrences:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>
								{invoice?.standingData.occurrences}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{!invoice.standing && invoice.dueDate && ( invoice.status !== 'ACCOUNT' || ( invoice.client?.terms || 0 ) > 0 ) && !pickup && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									{!isEstimateType( invoice.type ) ? 'Due:' : 'Expires:'}
								</Typography>
							</TableCell>
						)}
						<TableCell>
							<Typography>
								{safeDateFns.isSameDay( invoice.standingDate, invoice.dueDate ) && !isEstimateType( invoice.type )
									? 'Upon receipt'
									: safeFormatInTimeZone( invoice.dueDate, 'PP', timezone )}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				<TableWrapper>
					{!isMobile && !pickup && (
						<TableCell>
							<Typography color='text.secondary'>
								Status:
							</Typography>
						</TableCell>
					)}
					<TableCell align='right'>
						<CommerceStatusChip clientView cellData={invoice} isPdf={isPdf}/>
					</TableCell>
				</TableWrapper>
				{invoice.deliveryStatus && (
					<TableWrapper>
						{!isMobile && !pickup && (
							<TableCell>
								<Typography color='text.secondary'>
									Tag:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<DeliveryStatusChip
								disableOnClick
								order={invoice}
							/>
						</TableCell>
					</TableWrapper>
				)}
				{invoice.po && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									PO:
								</Typography>
							</TableCell>
						)}
						<TableCell align='right'>
							<Typography>
								{invoice.po}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{!pickup && (
					<TableWrapper>
						{!isMobile && (
							<TableCell>
								<Typography color='text.secondary'>
									Remaining:
								</Typography>
							</TableCell>
						)}
						<TableCell>
							<Typography sx={{ fontSize: '18px !important', fontWeight: 'bold' }}>
								{currencyFormat( remainingAmount )}
							</Typography>
						</TableCell>
					</TableWrapper>
				)}
				{!pickup && invoice.type === 'INVOICE' && invoice.metadata?.hideClientLink && invoice.client && invoicesBalance > 0 && (
					<TableWrapper>
						<TableCell>
							<Stack direction='row' spacing={1} alignItems='center' justifyContent='end'>
								<Button
									variant='text'
									component='a'
									target='_blank'
									color='primary'
									href={`/${hasSameCompany
										? 'dashboard/commerce'
										: 'client'}/${toLower( invoice.type )}s?s=${encodeURIComponent( Buffer.from( JSON.stringify( {
										filters: [ invoice.client && {
											id   : 'client.id',
											value: {
												$eq : invoice.client.id,
												temp: { checked: true },
											},
										}, {
											id   : 'type',
											value: {
												$eq : 'INVOICE',
												temp: { checked: true },
											},
										}, {
											id   : 'status',
											value: {
												$in : [ 'SENT', 'VIEWED', 'PARTIALLY_PAID', 'OPEN' ],
												temp: { checked: true },
											},
										} ].filter( Boolean ),
									} ) ).toString( 'base64' ) )}`}>
									Past: {currencyFormat( invoicesBalance || 0 )}
								</Button>
								<Tooltip title='The total of all sent invoices'>
									<HelpCenterRoundedIcon sx={{ fontSize: 16, borderRadius: 1, color: 'text.secondary' }}/>
								</Tooltip>
							</Stack>
						</TableCell>
					</TableWrapper>
				)}
			</Stack>
		</Fragment>
	);
}
